import { Cookies } from "react-cookie-consent"

// Set to the same value as the web property used on the site
const gaProperty = "UA-73037105-4"
const disableStr = "ga-disable-" + gaProperty

export const checkOptOut = () => {
  return document.cookie.indexOf(disableStr + "=true") > -1
}

export const blockGoogleAnalytics = () => {
  window[disableStr] = true
}

export const gaOptout = () => {
  document.cookie =
    disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/"
  Cookies.remove("_gat")
  Cookies.remove("_ga")
  Cookies.remove("_ga_J99JKVVYVE")
  Cookies.remove("_gid")
  Cookies.set("gatsby-gdpr-google-analytics", false)
  window[disableStr] = true
}
