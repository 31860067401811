import React from "react"
import { div } from "../styles/logo.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Logo = function (props) {
  return props.header === true ? (
    <Link className={div} to="/" aria-label="Link to Homepage">
      <StaticImage
        src="../images/pages/logo_black.png"
        alt="DeBaCode gUG Logo"
        placeholder="Blurred"
        height={65}
        loading="eager"
        backgroundColor="white"
        style={{
          width: 248,
        }}
        imgStyle={{
          width: 248,
          height: 65,
        }}
      />
    </Link>
  ) : (
    <Link className={div} to="/" aria-label="Link to Homepage">
      <StaticImage
        src="../images/pages/logo_white.png"
        width={200}
        alt="DeBaCode gUG Logo"
        layout="constrained"
        placeholder="blurred"
      />
    </Link>
  )
}

export default Logo
