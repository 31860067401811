import React from "react"
import { navigation, content } from "../styles/page.module.css"
import ContactInfo from "./ContactInfo"
import Navbar from "./Navbar"
import SocialMediaButtons from "./SocialMediaButtons"

import CookieConsent from "react-cookie-consent"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Link } from "gatsby"
import {
  checkOptOut,
  blockGoogleAnalytics,
} from "../services/googleAnalyticsHelper"
import PlausibleAnalytics from "./PlausibleAnalytics"

const Page = ({ children }) => {
  const [t] = useTranslation()
  const location = useLocation()

  return (
    <div>
      <PlausibleAnalytics />
      <div className={navigation}>
        <Navbar />
      </div>
      <SocialMediaButtons />
      <div className={content}>{children}</div>
      <ContactInfo />
      <CookieConsent
        location="bottom"
        buttonText={t("Accept")}
        declineButtonText={t("Decline")}
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton
        sameSite="Lax"
        style={{
          alignItems: "center",
        }}
        buttonStyle={{
          backgroundColor: "var(--main-pink)",
          color: "white",
        }}
        declineButtonStyle={{
          backgroundColor: "var(--main-gray)",
          color: "white",
        }}
        flipButtons
        onAccept={() => {
          if (!checkOptOut()) {
            initializeAndTrack(location)
          } else {
            blockGoogleAnalytics()
          }
        }}
        buttonId="acceptButton"
        declineButtonId="declineButton"
      >
        {t("CookieMessage")} (
        <Link to="/contact#datenschutz">{t("DataProtection")}</Link>)
      </CookieConsent>
    </div>
  )
}

export default Page
