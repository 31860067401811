import React from "react"
import { Link } from "gatsby"
import {
  link,
  activeLink,
  languagePicker,
  submit,
} from "../styles/navbar-links.module.scss"
import { useTranslation } from "react-i18next"

function NavbarLinks() {
  const [t, i18n] = useTranslation()
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }
  return (
    <>
      <Link className={link} to="/technology" activeClassName={activeLink}>
        {t("Technology")}
      </Link>
      <Link className={link} to="/about" activeClassName={activeLink}>
        {t("About us")}
      </Link>
      <Link className={link} to="/joinUs" activeClassName={activeLink}>
        {t("Participate")}
      </Link>
      <span className={languagePicker}>
        <span
          className={link}
          onClick={() => changeLanguage("de")}
          onKeyDown={event => {
            event.keyCode === 13 && changeLanguage("de")
          }}
          href="#"
          role="button"
          tabIndex="0"
        >
          de
        </span>
        |
        <span
          className={link}
          onClick={() => changeLanguage("ba")}
          onKeyDown={event => {
            event.keyCode === 13 && changeLanguage("ba")
          }}
          href="#"
          role="button"
          tabIndex="0"
        >
          ba
        </span>
      </span>
      <Link className={`${link} ${submit}`} to="/contact">
        {t("Contact")}
      </Link>
    </>
  )
}

export default NavbarLinks
