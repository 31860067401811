import React from "react"
import {
  container,
  inner,
  title,
  small,
  logo,
  links,
  link,
  about,
  linkmail,
} from "../styles/contact-info.module.css"
import facebook from "../images/social_media/facebook_white.png"
import instagram from "../images/social_media/instagram_white.png"
import linkedin from "../images/social_media/linkedin_white.png"
import mail from "../images/social_media/mail_white.png"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "./Layout"
import Logo from "./Logo.js"

const ContactInfo = () => {
  const [t] = useTranslation()

  return (
    <div className={container}>
      <Layout>
        <div className={inner}>
          <h1 className={title}>
            {t("Make software more social.")}
            <br />
            Deba!
          </h1>
          <div className={small}>
            <div className={logo}>
              <Logo header={false} />
            </div>
            <p>
              <Link to="/contact#datenschutz" className={small}>
                {t("DataProtection")}
              </Link>{" "}
              |{" "}
              <Link className={small} to="/contact#impressum">
                {t("Imprint")}
              </Link>
            </p>
            <p className={small}>
              © DeBaCode gemeinnützige UG <br />
              (haftungsbeschränkt)
            </p>
            <p className={small}>All rights reserved.</p>
          </div>

          <ul className={links}>
            <li key={1}>
              <Link to="/technology" className={link}>
                {t("Technology")}
              </Link>
            </li>
            <li key={2}>
              <Link to="/about" className={link}>
                {t("About us")}
              </Link>
            </li>
            <li key={3}>
              <Link to="/joinUs" className={link}>
                {t("Participate")}
              </Link>
            </li>
          </ul>
          <ul className={about}>
            <li key={4}>
              <span className={small}>Telefon</span>
            </li>
            <li key={5}>+49 (0) 156 78 343 545 </li>
            <li key={6}>
              <span className={small}>Email</span>
            </li>
            <li key={7}>
              <a href="mailto:kontakt@debacode.de" className={linkmail}>
                kontakt@<wbr></wbr>debacode.de
              </a>
            </li>
          </ul>
          <div>
            <a href="mailto:kontakt@debacode.de" className={link}>
              <img src={mail} alt="Mail" />
            </a>
            <a
              href="https://www.linkedin.com/organization-guest/company/debacode"
              className={link}
            >
              <img src={linkedin} alt="LinkedIn" />
            </a>
            <a href="https://www.instagram.com/debacode/" className={link}>
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://www.facebook.com/debacode" className={link}>
              <img src={facebook} alt="Facebook" />
            </a>
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default ContactInfo
